<div class="container-fluid ps-md-0">
  <div class="row g-0">
    <div class="d-none d-md-flex col-md-4 col-lg-7 bg-image"></div>
    <div class="col-md-8 col-lg-5">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">
              <img class="my-2" src="assets/img/brand/argon-white.png" style="width: 200px">
              <!-- Sign In Form -->
              <form class="px-2" novalidate (ngSubmit)="login(f)" #f="ngForm">
                <div class="form-floating mb-3">
                  <label for="floatingInput">Usuario</label>
                  <input matInput
                         [(ngModel)]="usuario.usuario"
                         #name="ngModel"
                         type="text" class="form-control"
                         name="usuario"
                         placeholder="Ingresa tu nombre de usuario"
                         max="150"
                         required>
                  <span *ngIf="name.invalid" class="badge badge-primary">El campo 'usuario' es obligatorio</span>
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingPassword">Contraseña</label>
                  <input matInput
                         [(ngModel)]="usuario.password"
                         #contra="ngModel"
                         type="password" class="form-control"
                         placeholder="Ingresa tu contraseña"
                         name="contra"
                         required>
                  <span *ngIf="contra.invalid" class="badge badge-primary">El campo 'contraseña' es obligatorio</span>
                </div>

                <div class="form-check mb-3" style="cursor: pointer;">
                  <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck" style="cursor: pointer;">
                  <label class="form-check-label" for="rememberPasswordCheck" style="cursor: pointer;">
                    Mantener sesión
                  </label>
                </div>

                <div class="d-grid">
                  <button class="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit"  [disabled]="f.invalid">Accesar</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h4 *ngIf="tipo_arreglo == 0" class="modal-title" id="modal-basic-title">¿Que sistema desea administrar?</h4>
    <h4 *ngIf="tipo_arreglo == 1" class="modal-title" id="modal-basic-title">¿Que empresa desea administrar?</h4>
    <h4 *ngIf="tipo_arreglo == 2" class="modal-title" id="modal-basic-title">¿Que cliente desea administrar?</h4>
    <h4 *ngIf="tipo_arreglo == 3" class="modal-title" id="modal-basic-title">Seleccione el tipo de nómina</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-row">
        <div class="form-group col-md-12" *ngIf="tipo_arreglo == 1">
          <input type="text" class="form-control" placeholder="Buscar empresa" [(ngModel)]="busqueda" (keyup)="buscarEmpresa()" name="busqueda" style="text-transform: uppercase;">
          <div class="table-responsive mt-1" style="height: 250px;">
            <table class="table table-striped" style="overflow-y: scroll;">
              <thead *ngIf="tipo_arreglo == 1">
                <tr>
                  <th colspan="1">Empresa</th>
                </tr>
              </thead>
              <tbody *ngIf="tipo_arreglo == 1">
                <tr *ngFor="let empresa of empresas" (click)="redirigirPrincipal(empresa.id_empresa,2)" style="cursor: pointer;">
                    <td colspan="1">{{empresa.empresa}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-group col-md-12">
          <div *ngIf="tipo_arreglo == 0" class="list-group">
            <a *ngFor="let sistema of sistemas" style="cursor: pointer;" (click)="eleccion(sistema.id,sistema.id_perfil,2)" class="list-group-item list-group-item-action" aria-current="true">
              {{sistema.sistema}}
            </a>
          </div>
          <!-- <div *ngIf="tipo_arreglo == 1" class="list-group">
            <a *ngFor="let empresa of empresas" style="cursor: pointer;" (click)="redirigirPrincipal(empresa.id_empresa,2)" class="list-group-item list-group-item-action" aria-current="true">
              {{empresa.empresa}}
            </a>
          </div> -->
          <div *ngIf="tipo_arreglo == 2" class="list-group">
            <a *ngFor="let cliente of clientes" style="cursor: pointer;" (click)="redirigirPrincipal(cliente.id_cliente,2)" class="list-group-item list-group-item-action" aria-current="true">
              {{cliente.cliente}}
            </a>
          </div>
          <div *ngIf="tipo_arreglo == 3" class="list-group">
            <a *ngFor="let nomina of nominas" style="cursor: pointer;" (click)="redireccionNomina(nomina.id_nomina)" class="list-group-item list-group-item-action" aria-current="true">
              {{nomina.nomina}}
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-warning" aria-label="Close" (click)="d('Cross click')">Cancelar</button>
  </div>
</ng-template>