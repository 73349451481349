<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
         aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Brand -->
      <a class="navbar-brand pt-0 text-center">
        <img src="{{foto_empresa}}" class="navbar-brand-img" alt="...">
        <h4>SISTEMA DE RECLUTAMIENTO</h4>
      </a>
      <!-- User -->
      <ul class="nav align-items-center d-md-none">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a class="nav-link" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="{{url_foto}}">
              </span>
            </div>
          </a>
          <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </a>
            <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#!" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
      <!-- Collapse -->
      <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a  routerLinkActive="active" [routerLink]="['/dashboard']">
                <img src="./assets/img/brand/blue.png">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Menu -->
        <ul class="navbar-nav">
            <li *ngFor="let menuItem of menuItems" class="nav-item">
                <a *ngIf="menuItem.band == true" [attr.data-target]="'#' + menuItem.id" class="nav-link collapse.hide" [attr.data-toggle]="menuItem.tipo"  [attr.aria-expanded]="false"  [attr.aria-controls]="menuItem.id" style="cursor: pointer;" >
                    <i class="ni {{menuItem.icon}}"></i>
                    {{menuItem.title}}
                </a>
                <a *ngIf="menuItem.band == false" routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                  <i class="ni {{menuItem.icon}}"></i>
                    {{menuItem.title}}
                </a>
                <div *ngIf="menuItem.band == true" class="collapse pl-4" id="{{menuItem.id}}">
                  <ul class="navbar-nav" [style.background-color]="menuItem.bg">
                    <li *ngFor="let submenu of menuItem.submenu" class="nav-item">
                        <a routerLinkActive="active" [routerLink]="[submenu.path]" class="nav-link text-white">
                            <i class="ni {{submenu.icon}}"></i>
                            {{submenu.title}}
                        </a>
                    </li>
                  </ul>
                </div>
            </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3">
        <ul class="navbar-nav">
            <li (click)="cerrarSesion()" class="nav-item">
                <a routerLinkActive="active" class="nav-link" style="cursor: pointer;">
                    <i class="ni ni-button-power text-blue"></i>
                    Cerrar sesión
                </a>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header justify-content-center">
      <h4 class="modal-title" id="modal-basic-title">¿Que cliente desea utilizar?</h4>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="list-group">
              <a *ngFor="let cliente of clientes[0]" (click)="eleccion(cliente.id_cliente)" style="cursor: pointer;" class="list-group-item list-group-item-action" aria-current="true">
                {{cliente.cliente}}
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-warning" aria-label="Close" (click)="d('Cross click')">Cancelar</button>
    </div>
  </ng-template>
  