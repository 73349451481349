<div class="header " style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
<div class="row">
    <div class="col">
        <div class="card border-info">
            <div class="card-header">
                Movimiento de Bancos
            </div>
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                      <div class="col-sm-12 col-lg-3">
                          <mat-form-field class="w-100">
                            <mat-label>Buscar empresa</mat-label>
                            <input type="text"
                                    placeholder="Ingrese el nombre o id de la empresa"
                                    aria-label="Number"
                                    matInput
                                    (keyup)="buscarEmpresa()"
                                    [formControl]="filterControlEmpresa"
                                    [matAutocomplete]="auto"
                                    style="text-transform: uppercase;">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                                <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                                    {{empresa.empresa}}
                                </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-lg-3">
                          <mat-form-field class="w-100">
                            <mat-label>EJERCICIO</mat-label>
                            <mat-select [(ngModel)]="ejercicioActual" (selectionChange)="ejercicioSeleccionado($event)">
                              <mat-option *ngFor="let ejersel of ejercicio" [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-lg-3">
                          <mat-form-field class="w-100">
                            <mat-label>MES</mat-label>
                            <mat-select [(ngModel)]="mesActual" (selectionChange)="mesSeleccionado($event.value)">
                              <mat-option *ngFor="let messel of meses" [value]="messel.id">{{messel.mes}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>  
                        
                        <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-info" (click)="cargarModal(content)" title="Agregar mov"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- Modal -->
<ng-template #content let-modalAlta>
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">{{modal_alta.titulo}}</h2>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>CONCEPTOS</mat-label>
                        <mat-select [(ngModel)]="modal_alta.formulario.concepto_selec" (selectionChange)="conceptoSeleccionado($event)">
                          <mat-option *ngFor="let ejersel of modal_alta.formulario.conceptos" [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <input style="position: relative;top: -7px;" 
                        [min]="modal_alta.formulario.fecha.min_date" 
                        [max]="modal_alta.formulario.fecha.max_date" 
                        matInput [matDatepicker]="picker"
                        [(ngModel)]="modal_alta.formulario.fecha.resu">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 colm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Importe</mat-label>
                        <input matInput [(ngModel)]="modal_alta.formulario.importe" (blur)="transformAmount($event)">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>BENEFICIARIOS</mat-label>
                        <mat-select [(ngModel)]="modal_alta.formulario.beneficiario_selec" (selectionChange)="beneficiarioSeleccionado($event)">
                          <mat-option *ngFor="let ejersel of modal_alta.formulario.beneficiarios" [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="modalAlta.dismiss()">Cerrar</button>
        <button type="button" class="btn btn-success">Guardar</button>
    </div>
</ng-template>