<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
        <!-- Toggler -->
        <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed" aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
        <!-- Brand -->
        <a class="navbar-brand pt-0">
            <img src="{{foto_empresa}}" class="navbar-brand-img" alt="...">
            <h4>SISTEMA DE CONTABILIDAD</h4>
        </a>
        <!-- User -->
        <ul class="nav align-items-center d-md-none">
            <li class="nav-item" ngbDropdown placement="bottom-right">
                <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
                    <i class="ni ni-bell-55"></i>
                </a>
                <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0)">Action</a>
                    <a class="dropdown-item" href="javascript:void(0)">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
                </div>
            </li>
            <li class="nav-item" placement="bottom-right">
                <a class="nav-link" role="button">
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="./assets/img/defaults/ico_default_admin.jpg">
            </span>
                    </div>
                </a>
            </li>
        </ul>
        <!-- Collapse -->
        <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
            <!-- Collapse header -->
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <a routerLinkActive="active" [routerLink]="['/dashboard']">
                            <img src="{{foto_empresa}}">
                        </a>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
                    </div>
                </div>
            </div>
            <!-- Menu -->
            <ul class="navbar-nav">
                <li *ngFor="let menuItem of menuItems" class="nav-item">
                    <a *ngIf="menuItem.band == true" [attr.data-target]="'#' + menuItem.id" class="nav-link collapse.hide" [attr.data-toggle]="menuItem.tipo" [attr.aria-expanded]="false" [attr.aria-controls]="menuItem.id" style="cursor: pointer;">
                        <i class="ni {{menuItem.icon}}"></i> {{menuItem.title}}
                    </a>
                    <a *ngIf="menuItem.band == false" routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                        <i class="ni {{menuItem.icon}}"></i> {{menuItem.title}}
                    </a>
                    <div *ngIf="menuItem.band == true" class="collapse pl-4" id="{{menuItem.id}}">
                        <ul class="navbar-nav">
                            <li *ngFor="let submenu of menuItem.submenu" class="nav-item">
                                <a routerLinkActive="active" [routerLink]="[submenu.path]" class="nav-link">
                                    <i class="ni {{submenu.icon}}"></i> {{submenu.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a data-target="#rh_herramienta" class="nav-link collapse.hide" data-toggle="collapse"  aria-expanded="false"  aria-controls="rh_herramienta" style="cursor: pointer;" >
                      <i class="fas fa-toolbox text-blue"></i>
                      Herramientas
                  </a>
                    <div class="collapse pl-4" id="rh_herramienta">
                      <ul class="navbar-nav bg-blue">
                        <li class="nav-item">
                            <app-subir-xml></app-subir-xml>
                        </li>
                      </ul>
                    </div>
                </li>
            </ul>
            <!-- Divider -->
            <hr class="my-3">
            <ul class="navbar-nav">
                <li (click)="cerrarSesion()" class="nav-item">
                    <a routerLinkActive="active" class="nav-link" style="cursor: pointer;">
                        <i class="ni ni-button-power text-blue"></i> Cerrar sesión
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>