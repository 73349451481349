<a routerLinkActive="active" style="cursor: pointer;" class="nav-link text-white" (click)="openModal()">
    <i class="fas fa-cloud-upload-alt"></i>
    Subir XML's
</a>
<ng-template #content let-c="close" let-d="dismiss" class="">
    <div class="container px-3 py-3">
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Seleccionar empresa</mat-label>
                    <input type="text"
                            placeholder="Ingrese el nombre o id de la empresa"
                            aria-label="Number"
                            matInput
                            (keyup)="buscarEmpresa()"
                            [formControl]="filterControlEmpresa"
                            [matAutocomplete]="auto"
                            style="text-transform: uppercase;">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                        <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                            {{empresa.empresa}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="file-upload">
                    <div class="file-select">
                      <div class="file-select-button" id="fileName">Seleccionar archivos</div>
                      <div class="file-select-name" id="noFile">{{text_input}}</div> 
                      <input type="file" name="chooseFile" id="chooseFile" (change)="seleccionarDir($event)" multiple [disabled]="id_empresa != 0 ? false : true">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="height: 250px; overflow-y: auto;">
                <ul class="list-group">
                    <li class="list-group-item mt-1" style="height: auto !important;" *ngFor="let file of list_files">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-9  {{file.start ? 'text-start' : 'text-justify'}}">{{file.nombre}}</div>
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-4">
                                            <button  *ngIf="!file.start" title="Eliminar Archivo" (click)="eliminarArchivo(file.n_file)" class="btn-warning btn btn-sm"><i class="fas fa-minus-circle"></i></button>
                                        </div>
                                        <div class="col-4">
                                            <ng-template #popContent>
                                                <ul style="max-height: 150px;overflow-y: auto;margin: 0;padding-left: 15px;">
                                                    <li *ngFor="let file of file.detalle" class="px-0">{{file}}</li>
                                                </ul>
                                            </ng-template>
                                            <ng-template #popTitle>Detalle del Error</ng-template>

                                            <button *ngIf="!file.start && !file.success" class="btn-info btn btn-sm" [ngbPopover]="popContent" [popoverTitle]="popTitle"><i class="fas fa-info-circle" title="Ver detalle"></i></button>
                                        </div>
                                        <div class="col-4 d-flex justify-content-center align-items-center">
                                            <mat-spinner *ngIf="file.reload == 1" value="indeterminate" [diameter]="20"></mat-spinner>
                                            <i *ngIf="file.reload == 2" class="fas fa-check text-success"></i>
                                            <i *ngIf="file.reload == 3" class="fas fa-times text-danger"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <button class="btn btn-success float-right" (click)="altaBobedaXML()" [disabled]="btn_dis">Guardar</button>
                <button class="btn btn-danger float-right mr-2" (click)="closeModal()">Cancelar</button>
            </div>
        </div>
    </div>
</ng-template>